@import 'src/styles/helpers';

.cookiesPolicyBanner {
  position: fixed;
  bottom: 64px;
  left: 120px;
  width: 484px;
  border-radius: 16px;
  z-index: 5;
  background: var(--primary-600);
  padding: 44px;

  @include media(desktopHuge) {
    left: 120px;
  }

  @include media(desktopSmall) {
    left: var(--container-padding-desktopSmall);
  }

  @include media(landscape) {
    left: var(--container-padding-landscape);
  }

  @include media(portrait) {
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    padding: 24px;

  }

  @include media(mobile) {
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    padding: 24px;
  }

}

.title {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.6px;
  color: var(--primary-10);
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include media(portrait) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
  }

  @include media(mobile) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.48px;
    margin-bottom: 12px;
  }

  img {
    width: 24px;
    height: auto;
    margin-right: 8px;
  }
}

.text {
  font-size: 16px;
  line-height: 28px;
  color: var(--secondary-50);
  margin-bottom: 32px;

  @include media(portrait) {
    font-size: 14px;
    line-height: 24px;
  }

  @include media(mobile) {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  & > a {
    text-decoration: underline;

    &:hover {
      color: var(--secondary-10);
    }
  }
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @include media(mobile) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
