@import 'src/styles/helpers';

.footer {
  padding-top: 144px;
  padding-bottom: 64px;
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  background-color: var(--primary-10);

  @include media(tablet) {
    padding-top: 96px;
  }

  @include media(mobile) {
    padding-top: 96px;
  }
}

.contacts {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 550px 500px;
  gap: 20px;
  margin-bottom: 144px;

  @include media(tablet) {
    grid-template-columns: 1fr;
    margin-bottom: 96px;
  }

  @include media(portrait) {
    gap: 24px;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    margin-bottom: 124px;
  }
}

.content {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto 1fr;

  @include media(tablet) {
    grid-template-columns: 1fr repeat(2, auto);
    align-items: flex-start;
    justify-content: flex-end;
    gap: 64px;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    gap: 48px;
    text-align: center;
  }
}

.copyright {
  margin-top: 48px;
  text-align: center;
  font-size: 12px;
  line-height: 2;
  color: var(--secondary-400);

  @include media(tablet) {
    margin-top: 64px;
  }

  @include media(mobile) {
    margin-top: 72px;
  }
}

.logo {
  img {
    display: block;
  }

  @include media(tablet) {
    order: 1;
  }

  @include media(mobile) {
    order: 1;

    img {
      margin: 0 auto;
    }
  }
}

.menu {
  display: flex;
  justify-content: flex-start;
  gap: 48px;

  @include media(tablet) {
    order: 2;
    flex-direction: column;
    gap: 32px;
  }

  @include media(mobile) {
    order: 2;
    flex-direction: column;
    gap: 32px;
  }

  &__link {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 12px 0;
    transition: color 0.2s ease;
    color: var(--primary-800);

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-900);
    }
  }
}

.info {
  display: flex;
  justify-content: flex-end;
  gap: 48px;

  @include media(tablet) {
    order: 3;
    flex-direction: column;
    gap: 8px;
    margin-top: -12px;
  }

  @include media(mobile) {
    order: 3;
    flex-direction: column;
    gap: 8px;
    margin-top: -12px;
  }

  &__link {
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-800);
    padding: 12px 0;
    transition: color 0.2s ease;

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-900);
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      color: var(--primary-500);
      vertical-align: middle;
    }
  }
}

.title {
  font-size: 96px;
  line-height: 1;
  letter-spacing: -2.88px;
  text-transform: uppercase;
  color: var(--primary-700);
  font-weight: bold;
  position: relative;
  font-family: Gontserrat, sans-serif;

  @include media(tablet) {
    font-size: 56px;
    line-height: 68px;
    letter-spacing: -1.68px;
    max-width: 650px;
  }

  @include media(mobile) {
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1.68px;
  }
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-800);
  margin-bottom: 48px;

  @include media(tablet) {
    max-width: 475px;
    font-size: 14px;
    line-height: 24px;
  }

  @include media(mobile) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}

.blur {
  position: absolute;
  opacity: 0.5;
  background: linear-gradient(
    140deg,
    #2de8ff 9.19%,
    #34ddff 12.86%,
    #5ca1fd 34.69%,
    #7975fb 53.26%,
    #8a5afa 67.64%,
    #9150fa 76.08%
  );
  filter: blur(93px);
  pointer-events: none;
  user-select: none;
  z-index: 0;

  &_1 {
    display: none;
  }

  &_2 {
    left: -148px;
    top: 213px;
    width: 636.914px;
    height: 81.527px;
    border-radius: 540px;
    transform: rotate(-21.151deg);
  }

  @include media(mobile) {
    display: none;
  }
}

.vector {
  transform: rotate(-23.9deg);
  position: absolute;
  z-index: 0;
  pointer-events: none;
  user-select: none;
  bottom: 50px;
  right: -328px;
  width: 566px;
  height: 566px;
  opacity: 0.2;

  @include media(desktopBig) {
    width: 861px;
    height: 861px;
    right: -500px;
    bottom: -150px;
  }

  @include media(desktopSmall) {
    right: -370px;
  }

  @include media(tablet) {
    width: 510px;
    height: 510px;
    bottom: 120px;
    right: -300px;
  }

  @include media(mobile) {
    width: 510px;
    height: 510px;
    bottom: 300px;
    right: -380px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.button {
  position: relative;

  @include media(tablet) {
    font-size: 12px !important;
    line-height: 14px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  @include media(mobile) {
    display: block;
    width: 100%;
  }
}

.policyLink {
  display: inline-block;
  color: var(--primary-900);
  margin-left: 4px;

  &:hover {
    text-decoration: underline;
  }
}

.vector1 {
  position: absolute !important;
  top: 350px;
  right: 400px;

  @include media(desktop) {
    right: -200px;
  }

  @include media(desktopSmall) {
    right: -200px;
  }

  @include media(tablet) {
    top: 200px;
    right: 200px;
  }

  @include media(mobile) {
    right: -50px;
  }
}

.vector2 {
  position: absolute !important;
  bottom: 40px;
  right: 815px;

  @include media(desktop) {
    right: 300px;
  }

  @include media(desktopSmall) {
    right: 300px;
  }

  @include media(tablet) {
    right: unset;
    bottom: 250px;
    left: 300px;
  }

  @include media(mobile) {
    right: 300px;
    bottom: 200px;
  }
}

.vector3 {
  position: absolute !important;
  top: 500px;
  right: 900px;

  @include media(desktop) {
    right: 400px;
  }

  @include media(desktopSmall) {
    right: 400px;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector4 {
  position: absolute !important;
  top: 400px;
  right: 1150px;

  @include media(desktop) {
    right: 700px;
  }

  @include media(desktopSmall) {
    right: 700px;
  }

  @include media(tablet) {
    right: unset;
    left: 0;
  }

  @include media(mobile) {
    display: none;
  }
}

.vector5 {
  position: absolute !important;
  top: 350px;
  left: 200px;

  @include media(desktop) {
    top: 100px;
    right: 200px;
    left: unset;
  }

  @include media(desktopSmall) {
    top: 100px;
    right: 200px;
    left: unset;
  }

  @include media(tablet) {
    display: none;
  }

  @include media(mobile) {
    display: none;
  }
}